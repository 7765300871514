import React from 'react';
import styles from './Home.module.css'
import s from "../../../styles/styles.module.css"
import classnames from "classnames";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import exchange from "../../../assets/newImg/publicPages/exchange.svg"
import cards from "../../../assets/newImg/publicPages/cards.svg"
import trustedPayment from "../../../assets/newImg/publicPages/trusted_payment.svg"
import mainAttention from "../../../assets/newImg/publicPages/main_attention.svg"
import efficiently from "../../../assets/newImg/publicPages/efficiently.svg"
import reliably from "../../../assets/newImg/publicPages/reliably.svg"
import team from "../../../assets/newImg/publicPages/team.svg"
import messages from "../../../assets/newImg/publicPages/messages.png"
import messagesMob from "../../../assets/newImg/mobile/messagesMob.png"
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import {useNavigate} from "react-router-dom";
import {motion} from 'framer-motion';
import {brandConfig} from '../../../config/config';

const Home = () => {

    const navigate = useNavigate()

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={s.wrapper}>
            <div className={s.container}>
                <div className={styles.container}>
                    <div className={styles.title_block}>
                        <h1 className={styles.title}>
                            Welcome <br className={styles.mobile_break}/> to {brandConfig.companyNameSimple.toUpperCase()}
                            <span className={styles.title_color}>Redefining Financial Services</span>
                        </h1>
                        <img
                            className={styles.main_attention}
                            src={mainAttention}
                            alt=""
                        />
                    </div>
                    <span className={styles.description}>
                  At {brandConfig.companyNameSimple}, we are committed to enhancing your financial experience through a comprehensive range of services
                  tailored to your unique needs.
                </span>
                    <div className={styles.btn_block}>
                        <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                    </div>
                </div>

                <div className={classnames(styles.block, styles.gray_bg, styles.flex)}>
                    <div>
                        <div className={classnames(styles.block_title, styles.color_title_black, styles.fix_width)}>
                            Foreign Exchange Excellence
                        </div>
                        <div className={styles.description_wrapper}>
                        <span className={styles.text_align}>
                            Navigating the world of foreign exchange can be daunting, but with {brandConfig.companyNameSimple}, it becomes a
                            seamless and hassle-free experience.
                        </span>
                        <span className={styles.text_align}>
                            Our team of seasoned professionals is here to provide expert guidance and competitive rates,
                            ensuring that your currency exchange needs are met with efficiency and reliability.
                        </span>
                        <span className={styles.text_align}>
                            Whether it&apos;s for your international travels or investment endeavors, we are committed to
                            optimizing your foreign exchange transactions.
                        </span>
                        </div>

                    </div>
                    <div>
                        <img
                            src={exchange}
                            alt="Landscape"
                            className={styles.exchange_img}
                        />
                    </div>
                </div>

                <div className={classnames(styles.flex, styles.gap20, styles.mt20)}>
                    <div className={classnames(styles.black_bg, styles.block)}>
                        <div className={classnames(styles.block_title, styles.color_title_white)}>
                            Seamless <br/>
                            Money Transfers
                        </div>
                        <span className={styles.light_gray}>
                        We understand the importance of seamless money transfers, whether you&apos;re sending funds locally
                        or internationally. With {brandConfig.companyNameSimple}, you can rely on our secure and reliable platform to handle
                        your transfers with utmost care and precision. Our commitment to meticulous attention to detail
                        ensures that your funds reach their intended destination promptly and securely, giving you peace of mind.
                    </span>
                    </div>
                    <div className={classnames(styles.blue_bg, styles.block)}>
                        <div className={classnames(styles.block_title, styles.color_title_black)}>
                            Effortless <br/>
                            Money Orders
                        </div>

                        <span>
                            When it comes to issuing or redeeming money orders, simplicity and convenience are our top
                            priorities. At {brandConfig.companyNameSimple}, we streamline the process, allowing you to handle your financial obligations
                            effortlessly.
                        </span>

                        <div className={styles.btn_wrapper}>
                            <div className={styles.btn_block}>
                                <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                            </div>
                        </div>


                    </div>
                </div>

                <div className={styles.benefits_wrapper}>
{/*
                    <div className={classnames(styles.flex, styles.benefit_block)}>
                        <div className={styles.fixed_width}>
                            <div className={classnames(styles.block_title, styles.color_title_black)}>
                                Embracing the World of Virtual Currencies
                            </div>
                            <span>
                        At {brandConfig.companyNameSimple}, we are proud to be crypto-friendly. We recognize the significance of digital
                        currencies in today&apos;s financial landscape and offer comprehensive solutions to cater to your
                        crypto-related needs. From onboarding to trading and secure storage, we provide a seamless and
                        user-friendly experience, ensuring that you can embrace the world of virtual currencies confidently.
                    </span>
                        </div>

                        <div>
                            <img
                                src={virtualCurrencies}
                                alt="Landscape"
                                className={styles.currency_img}
                            />
                        </div>
                    </div>
*/}

                    <div className={classnames(styles.flex, styles.benefit_block_revert)}>
                        <div className={styles.fixed_width}>
                            <div className={classnames(styles.block_title, styles.color_title_black)}>
                                Virtual and Physical Card Solutions
                            </div>
                            <span>
                            As a trusted payment service provider, we are here to simplify your financial transactions. From
                            everyday payments to online shopping, we offer a secure and convenient payment platform
                            that meets your evolving needs. With {brandConfig.companyNameSimple}, you can enjoy a seamless payment experience,
                            backed by our commitment to your satisfaction.
                        </span>
                        </div>

                        <div>
                            <img
                                src={cards}
                                alt="Landscape"
                                className={styles.cards_img}
                            />
                        </div>
                    </div>

                    <div className={classnames(styles.flex, styles.benefit_block)}>
                        <div className={styles.fixed_width}>
                            <div className={classnames(styles.block_title, styles.color_title_black)}>
                                Your Trusted Payment Service Provider
                            </div>
                            <span>
                            In addition to our comprehensive services, we provide both virtual and physical card options
                            to our esteemed clients. Our virtual cards offer convenience and security for your online
                            transactions, while our physical cards provide you with a tangible payment solution that can
                            be used worldwide. With {brandConfig.companyNameSimple}, you can enjoy the best of both worlds, tailored to your
                            preferences and lifestyle.
                        </span>
                            <div className={styles.button_wrapper_benefit}>
                                <div className={styles.btn_block}>
                                    <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                                </div>
                            </div>

                        </div>

                        <div>
                            <img
                                src={trustedPayment}
                                alt="Landscape"
                                className={styles.trusted_payment_img}
                            />
                        </div>
                    </div>
                </div>

                <div className={classnames(styles.block, styles.gray_bg, styles.team_description_wrapper)}>
                    <h2 className={styles.team_description}>
                        Our team of experienced <br/>
                        professionals ensures that all your <br/>
                        needs are met&nbsp;
                        <span className={styles.decorated}>
                            <img src={efficiently} className={styles.efficiently} alt={""} width={238} height={55}/>
                            efficiently
                        </span> <br/>
                        and&nbsp;
                        <span className={styles.decorated}>
                        <img src={reliably} className={styles.reliably} alt={""} width={238} height={55}/>
                        reliably
                    </span>
                        <img className={styles.team_img} src={team} alt={""} height={464} width={317}/>
                    </h2>
                </div>

                <div className={styles.messages}>
                    <img src={messages} alt={""} className={styles.desktop_img}/>
                    <img src={messagesMob} alt={""} className={styles.desktop_mobile}/>
                </div>

                <div className={classnames(styles.contact_form_wrapper)}>
                    <ContactUsForm/>
                </div>
            </div>
        </div>
        </motion.div>
    );
};

export default Home;
