import React, {useEffect, useState} from 'react';
import ContactUsForm from "../../../components/ContactUsForm/ContactUsForm";
import s from "./Fees.module.css";
import styles from "../../../styles/styles.module.css";
import {motion} from 'framer-motion';
import minus from "../../../assets/newImg/publicPages/faq/minus.svg";
import plus from "../../../assets/newImg/publicPages/faq/plus.svg";
import {Collapse} from "antd";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import download from "../../../assets/newImg/mobile/download.svg"
import {pdfjs, Document, Page } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import {brandConfig} from '../../../config/config';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const {Panel} = Collapse;

const Fees = () => {

    const [isMobile, setIsMobile] = useState(false)
    const [numPages, setNumPages] = useState<number>();

    const maxWidth = 900;

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
            className={s.motion_div}
        >
            <div className={styles.container}>
                <div className={styles.wrapper}>

                    <div className={s.top_block}>
                        <div className={s.content}>

                            <div className={s.title}>
                                {brandConfig.companyNameSimple} Banking Fees Structure
                            </div>

                            <div>
                                <Collapse
                                    className={s.my_collapse}
                                    expandIconPosition={"end"}
                                    bordered={false}
                                    expandIcon={({isActive}) => isActive ?
                                        <img className={s.icon_minus} src={minus} alt=""/> :
                                        <img src={plus} className={s.icon_plus} alt=""/>}
                                    style={{
                                        background: "transparent",
                                        alignItems: 'center',
                                    }}
                                >
                                    <Panel header={"Fees For Individual Accounts"} className={s.panelStyle}
                                           key={"1"}>
                                        <div className={s.pdf}>
                                            <div className="Example__container__document">
                                                <Document file={"./Fees_for_Individual_Accounts.pdf"} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            renderAnnotationLayer={false}
                                                            renderTextLayer={false}
                                                            pageNumber={1}
                                                            width={isMobile ? 300 : maxWidth }
                                                        />
                                                    ))}
                                                </Document>
                                            </div>
                                            <div className={s.button_wrapper}>
                                                <a href="/Fees_for_Individual_Accounts.pdf" download={true}>
                                                    <MyBtn title={"Download PDF"} icon={download}/>
                                                </a>
                                            </div>
                                        </div>
                                    </Panel>

                                    <Panel header={"Fees For Business Accounts"} className={s.panelStyle}
                                           key={"2"}>
                                        <div className={s.pdf}>
                                            <div className="Example__container__document">
                                                <Document file={"./Fees_for_Business_Accounts.pdf"} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            renderAnnotationLayer={false}
                                                            renderTextLayer={false}
                                                            pageNumber={1}
                                                            width={isMobile ? 300 : maxWidth }
                                                        />
                                                    ))}
                                                </Document>
                                            </div>
                                            <div className={s.button_wrapper}>
                                                <a href="/Fees_for_Business_Accounts.pdf" download>
                                                    <MyBtn title={"Download PDF"} icon={download}/>
                                                </a>
                                            </div>
                                        </div>
                                    </Panel>
                                    <Panel header={"Fees For Cards"} className={s.panelStyle}
                                           key={"3"}>
                                        <div className={s.pdf}>
                                            <div className="Example__container__document">
                                                <Document file={"./Fees_for_Cards.pdf"} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                                    {Array.from(new Array(numPages), (el, index) => (
                                                        <Page
                                                            renderAnnotationLayer={false}
                                                            renderTextLayer={false}
                                                            pageNumber={1}
                                                            width={isMobile ? 300 : maxWidth }
                                                        />
                                                    ))}
                                                </Document>
                                            </div>
                                            <div className={s.button_wrapper}>
                                                <a href="/Fees_for_Cards.pdf" download>
                                                    <MyBtn title={"Download PDF"} icon={download}/>
                                                </a>
                                            </div>
                                        </div>
                                    </Panel>

                                </Collapse>
                            </div>
                        </div>
                    </div>

                    <ContactUsForm/>
                </div>
            </div>
        </motion.div>
    );
};

export default Fees;
