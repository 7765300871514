import React, { ChangeEvent, MouseEvent } from "react";
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import { ReactComponent as Plus } from "../../assets/newImg/personalAccount/plus.svg";
import attachedDoc from "../../assets/newImg/personalAccount/attached_document.svg";
import delete_document from "../../assets/newImg/personalAccount/delete_document.svg";
import rejectedDoc from "../../assets/newImg/personalAccount/rejected_document.svg";
import delete_rejected_document from "../../assets/newImg/personalAccount/rejected_close.svg";

type FileTypes =
    | ".png"
    | ".jpg"
    | ".jpeg"
    | ".pdf"
    | "image/png"
    | "image/jpeg"
    | "application/pdf";
type FileInputProps = {
    s: any;
    documents: any;
    inputId: string;
    allowedTypes: FileTypes[];
    identifier: string;
    maxFileSize: number;
    kycList?: any;
    documentTitle?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onImageClick?: (event: MouseEvent<HTMLImageElement>) => void;
};

const FileInput = (props: FileInputProps) => {
    const {
        s,
        inputId,
        allowedTypes,
        documents,
        identifier,
        maxFileSize,
        onChange,
        onImageClick,
    } = props;

    const allowedTypesString = allowedTypes.join(", ");

    const maxSize = maxFileSize * 1024 * 1024;

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            if (file.size > maxSize) {
                alert(
                    "The file size exceeds the " +
                        maxFileSize +
                        "MB limit. Please upload a smaller file."
                );
                event.target.value = "";
                return;
            }

            if (onChange) {
                onChange(event);
            }
        }
    };

    return (
        <>
            <div className={s.attached_file_wrapper}>
                <div className={s.btn_block}>
                    <TransparentButton
                        icon={
                            <Icon component={() => <Plus />} rev={undefined} />
                        }
                        title={"Add file"}
                        onClick={(e: any) => {
                            e.preventDefault();
                            // @ts-ignore
                            document.getElementById(inputId).click();
                        }}
                    />
                    <input
                        type="file"
                        accept={allowedTypesString}
                        id={inputId}
                        className={s.hidden_input}
                        onChange={handleFileChange}
                    />
                </div>
                {props.kycList ? (
                    <div className={s.attached_file}>
                        {
                            props.kycList[0].files.map(
                                (item: any, index: number) =>
                                    item.status === "Rejected" &&
                                    item.fileType === identifier &&
                                    documents[identifier] === "" && (
                                        <img
                                            key={index}
                                            src={rejectedDoc}
                                            alt=""
                                        />
                                    )
                            )
                        }

                        {documents[identifier]?.name && (
                            <img src={attachedDoc} alt="" />
                        )}

                        {documents[identifier]?.name && (
                            <span className={s.attached_file_name}>
                                {documents[identifier]?.name}
                            </span>
                        )}

                        {
                            props.kycList[0].files.map(
                                (item: any, index: number) =>
                                    item.status === "Rejected" &&
                                    item.fileType === identifier &&
                                    documents[identifier] === "" && (
                                        <span
                                            key={index}
                                            className={s.rejected_doc_name}
                                        >
                                            {props.documentTitle}
                                        </span>
                                    )
                            )
                        }

                        {
                            props.kycList[0].files.map(
                                (item: any, index: number) =>
                                    item.status === "Confirmed" &&
                                    item.fileType === identifier &&
                                    documents[identifier] === "" && (
                                        <span
                                            key={index}
                                            className={s.approved_doc_name}
                                        >
                                            {props.documentTitle}
                                        </span>
                                    )
                            )
                        }

                        {documents[identifier]?.name && (
                            <img
                                onClick={onImageClick}
                                className={s.delete_doc}
                                src={delete_document}
                                alt=""
                            />
                        )}

                        {
                            props.kycList[0].files.map(
                                (item: any, index: number) =>
                                    item.status === "Rejected" &&
                                    documents[identifier] === "" &&
                                    item.fileType === identifier && (
                                        <img
                                            className={s.delete_doc}
                                            src={delete_rejected_document}
                                            alt=""
                                            key={index}
                                        />
                                    )
                            )
                        }
                    </div>
                ) : (
                    <div className={s.attached_file}>
                        {documents[identifier]?.name && (
                            <img src={attachedDoc} alt="" />
                        )}
                        {documents[identifier]?.name}
                        {documents[identifier]?.name && (
                            <img
                                onClick={onImageClick}
                                className={s.delete_doc}
                                src={delete_document}
                                alt=""
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default FileInput;
