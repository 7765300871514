import React, {useEffect, useState} from 'react';
import styles from './Footer.module.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import MyBtn from "../ui/MyBtn/MyBtn";
import logo from '../../assets/newImg/publicPages/logo.svg'
import locationIcon from '../../assets/newImg/publicPages/location.svg'
import call from '../../assets/newImg/publicPages/call.svg'
import mail from '../../assets/newImg/publicPages/mail.svg'
import facebook from '../../assets/newImg/publicPages/facebook.svg'
import instagram from '../../assets/newImg/publicPages/instagram.svg'
import linkedin from '../../assets/newImg/publicPages/linkedin.svg'
import whatsapp from '../../assets/newImg/publicPages/whatsapp.svg'
import signal from '../../assets/newImg/publicPages/signal.svg'
import {
    ABOUT,
    CONTACTS, FEES,
    FOREIGN_EXCHANGE,
    LEGAL_AND_PRIVACY, MONEY_ORDERS, MONEY_TRANSFERS, PAYMENT_SERVICE_PROVIDER,
    WHY_US
} from "../../Constants/RoutesConstants";
import { brandLinks } from '../../config/config';
import {brandConfig} from '../../config/config';

const Footer = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [isFooterShow, setIsFooterShow] = useState(true)

    useEffect(() => {
        if (
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
        ) {
            setIsFooterShow(false)
        } else {
            setIsFooterShow(true)
        }
    },[location.pathname])

    return (
        <>
            {
                isFooterShow && <footer className={styles.footer_wrapper}>
                    <div className={styles.logo_block}>
                        <img src={logo} alt={""} width={168} height={40}/>
                        <span className={styles.registration}>MSB registration number: M23371461</span>
                        <span className={styles.copyright}>Copyright &copy; 2023 All rights reserved</span>
                    </div>

                    <div className={styles.links_block}>
                        <div className={styles.links_column}>
                            <span className={styles.links_title}>About</span>
                            <div className={styles.mobile_row}>
                                <Link className={styles.link} to={"/"}>Home</Link>
                                <Link className={styles.link} to={ABOUT}>About Us</Link>
                                <Link className={styles.link} to={WHY_US}>Why Us?</Link>
                                <Link className={styles.link} to={FEES}>Fees</Link>
                                <Link className={styles.link} to={CONTACTS}>Contacts</Link>
                            </div>

                        </div>

                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Services</span>
                            {/*<Link className={styles.link} to={VIRTUAL_CURRENCIES}>Virtual Currencies</Link>*/}
                            <Link className={styles.link} to={FOREIGN_EXCHANGE}>Foreign Exchange</Link>
                            <Link className={styles.link} to={MONEY_ORDERS}>Money Orders</Link>
                            <Link className={styles.link} to={MONEY_TRANSFERS}>Money Transfers</Link>
                            <Link className={styles.link} to={PAYMENT_SERVICE_PROVIDER}>Payment Service <br/> Provider</Link>
                        </div>

                        <div className={styles.links_column}>
                            <span className={styles.links_title}>Contacts</span>
                            <div className={styles.link}>
                                <div>
                                    <img src={locationIcon} alt={""} width={24} height={24}/>
                                </div>
                                <div>
                                    4 Robert Speck Parkway, <br/>
                                    Mississauga, ON L4Z 1S1, <br/>
                                    Canada
                                </div>
                            </div>
                            <div className={styles.link}>
                                <div>
                                    <img src={call} alt={""} width={24} height={24}/>
                                </div>
                                <div>
                                    +1 (888) 879-8570 <br/>
                                    +1 (888) 792-5272
                                </div>
                            </div>
                            <div>
                                <a className={styles.link} href={`mailto:${brandConfig.companyInfoEmailAddress}`}>
                                    <div>
                                        <img src={mail} alt={""} width={24} height={24}/>
                                    </div>
                                    <div>
                                        {brandConfig.companyInfoEmailAddress}
                                    </div>
                                </a>
                            </div>
                            <div className={styles.icons_block}>
                                <a href={brandLinks.facebook} target="_blank" rel="noreferrer">
                                    <img src={facebook} alt={""} width={28} height={28}/>
                                </a>
                                <img src={instagram} alt={""} width={28} height={28}/>
                                <a href={brandLinks.linkedin} target={"_blank"} rel="noreferrer">
                                    <img src={linkedin} alt={""} width={28} height={28}/>
                                </a>
                                <a href={brandLinks.whatsapp} target={"_blank"} rel="noreferrer">
                                <img src={whatsapp} alt={""} width={28} height={28}/>
                                </a>
                                <img src={signal} alt={""} width={28} height={28}/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className={styles.title}>
                            Join us!
                        </div>
                        <div className={styles.button_wrapper}>
                            <MyBtn title={"Get Started"} onClick={() => navigate("/sign_up")}/>
                        </div>
                        <Link className={styles.legal} to={LEGAL_AND_PRIVACY}>Legal & Privacy</Link>
                    </div>


                </footer>
            }
        </>

    );
};

export default Footer;
