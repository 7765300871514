import React, {Dispatch, SetStateAction, useState} from 'react';
import {useAppDispatch} from "../../../hooks/redux";
import {Formik} from "formik";
import {RegistrationThunk} from "../../../store/reducers/ActionCreators";
import s from "./SignUp.module.css";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import PhoneCodesInput from "../../../components/ui/PhoneCodesInput/PhoneCodesInput";
import * as yup from 'yup';
import CustomCheckbox from "../../../components/ui/customCheckbox/CustomCheckbox";
import MyInput from "../../../components/ui/MyInput/MyInput";
import {motion} from 'framer-motion';
import attention from "../../../assets/newImg/attention.svg";
import {setDisabled} from "../../../store/reducers/UserSlice";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
}

const validationSchema = yup.object({
    phone: yup.string()
        .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){0,20}(\s*)?$/, "Must be only digits")
        .min(6, "min 6")
        .max(20, "max 20")
        .required('Required field'),
    email: yup.string().required('Required field').email('invalid email address'),
    password: yup.string()
        .min(8, "min 8")
        .matches(/[1-9]+/, "Password must contain at least one number")
        .matches(/[a-z]+/, "Password must contain at least one lowercase")
        .matches(/[A-Z]+/, "Password must contain at least one uppercase")
        .required('Required field')
})

const SignUp = (props: PropsType) => {

    const dispatch = useAppDispatch()

    const [accountType, setAccountType] = useState("personal")
    const [error, setError] = useState("")

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.sign_in_wrapper}>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        phone: '',
                        type: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {

                        const str = values.phone.replace(/-/gi, '').replace(/(^[^ ]* )|[ ]+/g, '$1').replace("+", "")

                        const tempData = {
                            ...values,
                            type: accountType,
                            phone: str
                        }
                        await dispatch(RegistrationThunk(tempData))
                            .then(() => {
                                props.setCurrent(1)
                            }).catch((e) => {
                                if (e.response.data === "Phone already exist") {
                                    setError("Phone number already exists")
                                }
                                if (e.response.data === "This email already exist") {
                                    setError("Email already exists")
                                }
                            }).finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          resetForm,
                          setFieldValue,
                          handleChange,
                          handleSubmit
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.sign_in_content}>
                                <div className={s.title}>
                                    Create account
                                </div>
                                {
                                    error !== '' &&
                                    <div className={s.error_block}>
                                        <img src={attention} alt=""/>
                                        <div>
                                            {error}
                                        </div>
                                    </div>
                                }
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Phone number
                                    </div>
                                    <PhoneCodesInput
                                        id="phone"
                                        name="phone"
                                        value={values.phone}
                                        onChange={handleChange}
                                        error={errors.phone}
                                        touched={touched.phone}
                                    />
                                    <div className={s.error_message}>
                                        {errors.phone}
                                    </div>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Email address
                                    </div>
                                    <MyInput id="email"
                                             name="email"
                                             isError={errors.email}
                                             value={values.email}
                                             onChange={handleChange}
                                             touched={touched.email}
                                    />
                                    {errors.email &&
                                        <div className={s.error_message}>{errors.email}</div>}
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Create password
                                    </div>
                                    <MyInput id="password"
                                             name="password"
                                             password
                                             isError={errors.password}
                                             value={values.password}
                                             onChange={handleChange}
                                             touched={touched.password}
                                    />
                                    <div className={s.error_message}>
                                        {errors.password}
                                    </div>
                                </div>
                                <div className={s.password_rules}>
                                    The new password must contain a minimum of 8 characters with at least: 1 lowercase,
                                    1 uppercase and 1 number.
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        account type
                                    </div>
                                    <div className={s.flex_label}>
                                        <label className={s.flex_label}>
                                            <CustomCheckbox
                                                handleChange={() => setAccountType("personal")}
                                                id="Personal"
                                                name="Personal"
                                                isChecked={accountType === "personal"}
                                            />
                                            <span className={s.type_of_account}>Personal</span>
                                        </label>
                                        <label className={s.flex_label}>
                                            <CustomCheckbox
                                                handleChange={() => setAccountType("business")}
                                                id="Business"
                                                name="Business"
                                                isChecked={accountType === "business"}
                                            />
                                            <span className={s.type_of_account}>Business</span>
                                        </label>
                                    </div>
                                </div>
                                <div className={s.btn_block}>
                                    <MyBtn
                                        title={"Sign Up"}
                                        type={"submit"}
                                    />
                                </div>
                            </div>
                            <div className={s.dont_have_account}>
                                {/*<div>*/}
                                {/*    Do you have an account?*/}
                                {/*</div>*/}
                                {/*<Link className={s.link} to={"/login"}>Sign In</Link>*/}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>

    );
};

export default SignUp;
