import React, {Dispatch, SetStateAction, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/redux";
import s from "../KycBusinessStepTwo/KycBusinessStepTwo.module.css";
import CustomModal from "../../../../../../components/ui/CustomModal/CustomModal";
import MyBtn from "../../../../../../components/ui/MyBtn/MyBtn";
import * as yup from "yup";
import {Field, Formik} from "formik";
import {editKycThunk, setTokenState} from "../../../../../../store/reducers/ActionCreators";
import MyInput from "../../../../../../components/ui/MyInput/MyInput";
import CustomDropdown from "../../../../../../components/ui/CustomDropdown/CustomDropdown";
import legal_account_application from "../../../../../../assets/newImg/personalAccount/legal_account_application.png";
import classnames from "classnames";
import download_legal from "../../../../../../assets/newImg/personalAccount/download_legal_application.svg";
import {ReactComponent as Plus} from "../../../../../../assets/newImg/personalAccount/plus.svg";
import attachedDoc from "../../../../../../assets/newImg/personalAccount/attached_document.svg";
import delete_document from "../../../../../../assets/newImg/personalAccount/delete_document.svg";
import proofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png";
import companyCharter from "../../../../../../assets/newImg/personalAccount/company_charter.png";
import registrationSertificate from "../../../../../../assets/newImg/personalAccount/registration_sertificate.png";
import beneficiaryIdDoc from "../../../../../../assets/newImg/personalAccount/beneficiary_identity.png";
import beneficiaryIdcp from "../../../../../../assets/newImg/personalAccount/beneficiary_idcp.png";
import BeneficiaryProofOfAddress from "../../../../../../assets/newImg/personalAccount/proof_of_address.png";
import RegisterDirector from "../../../../../../assets/newImg/personalAccount/register_director.png"
import RegisterShareholder from "../../../../../../assets/newImg/personalAccount/register_shareholder.png"
import Poa from "../../../../../../assets/newImg/personalAccount/poa.png";
import license from "../../../../../../assets/newImg/personalAccount/license.png";
import {ReactComponent as Back}  from "../../../../../../assets/newImg/personalAccount/back.svg";
import {motion} from "framer-motion";
import rejectedDoc from "../../../../../../assets/newImg/personalAccount/rejected_document.svg";
import delete_rejected_document from "../../../../../../assets/newImg/personalAccount/rejected_close.svg";
import TransparentButton from "../../../../../../components/ui/TransparentButton/TransparentButton";
import Icon from "@ant-design/icons";
import {countries, phonecodes} from "../../../../../../Constants/StaticData";
import {setDisabled} from "../../../../../../store/reducers/UserSlice";
import SelectWithSearch from "../../../../../../components/ui/SelectWithSearch/SelectWithSearch";
import {Checkbox, DatePicker} from "antd";
import dayjs from "dayjs";
import { toBase64 } from '../../../../../../helpers/toBase64';
import {IPhoneNumber} from "../../../../../../types/interfaces";
import idCard from "../../../../../../assets/newImg/personalAccount/idCard.png";
import {brandConfig} from '../../../../../../config/config';
import FileInput from '../../../../../../components/FileInput/FileInput';

type PropsType = {
    current: number
    setCurrent: Dispatch<SetStateAction<number>>
}

const validationSchema = yup.object({
    phoneNumber: yup.string().required('Required field'),
    companyName: yup.string().required('Required field').max(90, 'max 90'),
    country: yup.string().required('Required field').max(90, 'max 90'),
    companyType: yup.string().required('Required field').max(100, 'max 100'),
    city: yup.string().required('Required field').max(50, 'max 50'),
    address: yup.string().required('Required field').max(255, 'max 255'),
    postalCode: yup.string().required('Required field').max(50, 'max 50'),
    email: yup.string().email('invalid format').required('Required field').max(70, 'max 70'),
    industryId: yup.string().required('Required field').max(90, 'max 90'),
    taxNumber: yup.string().required('Required field').max(90, 'max 90'),
    currencies: yup.array().min(1,"At least one account is required"),
    firstName: yup.string().required('Required field').max(90, 'max 90'),
    lastName: yup.string().required('Required field').max(90, 'max 90'),
    authorizedCitizenshipName: yup.string().required('Required field').max(90, 'max 90'),
    residenceCountryName: yup.string().required('Required field'),
    authorizedPersonCity:  yup.string().required('Required field').max(60, 'max 60'),
    authorizedPersonAddress:  yup.string().required('Required field').max(255, 'max 255'),
    authorizedPersonPostalCode:  yup.string().required('Required field').max(30, 'max 30'),
    otherSourcesOfWealth: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (isOtherSourcesOfWealth) return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    sourcesOfWealth: yup.array().test({
        message: 'At least one source is required',
        test: function (value) {
            const {isOtherSourcesOfWealth} = this.parent;
            if (!isOtherSourcesOfWealth) return value !== undefined && value !== null && value.length > 0;
            return true
        },
    }),
    financialRegulator: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {companyType} = this.parent;
            if (companyType === "Financial Institution") return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    regulatoryLicenseNumber: yup.string().test({
        message: 'Required field',
        test: function (value) {
            const {companyType} = this.parent;
            if (companyType === "Financial Institution") return value !== undefined && value !== null && value !== '';
            return true
        },
    }),
    website: yup.string().test({
        message: 'Website or Marketing Strategy required',
        test: function (value) {
            const { marketingStrategy } = this.parent;
            if ((value === undefined || value === null || value === '' ) && (marketingStrategy === undefined || marketingStrategy === null || marketingStrategy === '')) return false;
            return true
        },
    }),
    marketingStrategy: yup.string().test({
        message: 'Website or Marketing Strategy required',
        test: function (value) {
            const {website} = this.parent;
            if ((value === undefined || value === null || value === '' ) && (website === undefined || website === null || website === '')) return false;
            return true
        },
    }),
})

const EditKyc = (props: PropsType) => {

    const token: string = useAppSelector(state => state.userReducer.user.userToken)
    const newKycList = useAppSelector(state => state.userReducer.user.kycList)
    const dispatch = useAppDispatch()

    // @ts-ignore
    const phoneNumber = newKycList[0].phoneNumber.split(' ')

    const [documents, setDocuments] = useState<any>(
        {
            accountApplication: '',
            proofOfAddress: '',
            articlesMemorandums: '',
            registrationCertificate: '',
            beneficiaryIdDocument: '',
            beneficiaryIdcp: '',
            beneficiaryProofOfAddress: '',
            registerOfDirectorOrMembers: '',
            registerOfShareholders: '',
            poA: '',
            license: '',
            backOfIdCard: '',
        }
    )
    const docsError = false;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again later.");
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const initPhoneNumbers = useAppSelector(state => state.userReducer.user.phoneList)

    const [date, setDate] = useState({
        // @ts-ignore
        from: newKycList[0].registrationDate?.date,
        // @ts-ignore
        birth: newKycList[0].dateOfBirth?.date,
    })

    const sourcesOfWealthHandlerChange = (e: any, meta: any, form: any, name: string) => {
        form.setFieldValue("otherSourcesOfWealth", '');
        form.setFieldValue("isOtherSourcesOfWealth", false);
        const {checked} = e.target;
        if (checked) {
            form.setFieldValue("sourcesOfWealth", [...meta.value.sourcesOfWealth, name]);
        } else {
            const filteredArr = meta.value.sourcesOfWealth.filter((i: string) => i !== name)
            form.setFieldValue("sourcesOfWealth", filteredArr);
        }
    }

    const closeAndRedirect = () => {
        setIsModalOpen(false)
        props.setCurrent(0)
    }

    // @ts-ignore
    const owner = newKycList[0]?.owner

    const companyTypes = [
        'Sole Proprietorship',
        'Partnership',
        'Privately Owned Company (Limited Company)',
        'Publicly Listed Company (PLC)',
        'Government Owned Entity Trusts',
        'Foundations & Similar Entities',
        'Non-Government Organisations / Charities inc Religious bodies and place of worship',
        'Clubs and Societies',
        'GO (Majority Owned Subsidiary of State-Owned Company)',
        'Financial Institution',
    ]

    const natureOfBusinessArray = [
        'Personal',
        'Agriculture and Hunting',
        'Forestry',
        'Fishing',
        'Agricultural By-Products',
        'Coal Mining',
        'Oil Mining',
        'Iron Ore Mining',
        'Other Metal and Diamond Mining',
        'Other Mineral Mining',
        'Manufacture of Food/Drink/Tobacco',
        'Manufacture of Textiles/Leather/Fur/Furniture',
        'Manufacture of Wooden Products/Furniture',
        'Manufacture of Paper/Pulp/Allied Products',
        'Manufacture Of Chemicals Medical Petroleum Rubber Plastic Products',
        'Manufacture Of Pottery China Glass Stone',
        'Manufacture Of Iron Steel Non-Ferrous Metals Basic Industries',
        'Manufacture Of Metal Products Electrical And Scientific Engineering',
        'Manufacture Of Jewelry Musical Instruments Toys',
        'Electricity, Gas And Water',
        'Construction',
        'Wholesale Trade',
        'Retail Trade',
        'Catering Incl. Hotels',
        'Transport Storage',
        'Communications',
        'Finance And Holding Companies',
        'Insurance',
        'Business Services',
        'Real Estate Development Investment',
        'Central State Governments',
        'Community Services Defence Police Prisons Etc',
        'Social Services Education Health Care',
        'Personal Services - Leisure Services',
        'Personal Services - Domestic Laundry Repairs',
        'Personal Services - Embassies'
    ]

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 55,
        width: "100%",
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    const dateFormat = 'YYYY-MM-DD';

    const getCountryCode = (countryName: string) => {
        const country = phonecodes.find((entry) => entry.na === countryName);
        return country?.co;
    };

    const getPhoneNumbersArr = (): string[] => {
        const phonesArr: string[] | null = [];
        initPhoneNumbers?.forEach((item: IPhoneNumber) => {
            if (item.phoneNumber != null) {
                phonesArr.push(item.phoneNumber);
            }
        });
        return phonesArr;
    }

    return (
        <motion.div
            initial={{opacity: 0, scale: 1}}
            animate={{opacity: 1, scale: 1}}
            transition={{duration: 0.5}}
        >
            <div className={s.wrapper}>

                <CustomModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
                    <div className={s.flex_modal}>
                        <div className={s.modal_title}>
                            Success!
                        </div>
                        <div className={s.modal_subtitle}>
                            Your details will be sent for review.
                        </div>
                        <div className={s.button_block}>
                            <MyBtn title={"Ok"} onClick={() => closeAndRedirect()}/>
                        </div>
                    </div>
                </CustomModal>
                <CustomModal type="error" isModalOpen={isErrorModalOpen} setIsModalOpen={setIsErrorModalOpen}>
                    <div className={s.flex}>
                        <div className={s.modal_title}>
                            Oops!
                        </div>
                        <div className={s.modal_subtitle}>
                            {errorMessage}
                        </div>
                        <div className={s.button_block}>
                            <MyBtn buttonType="error" title={"Ok"} onClick={() => setIsErrorModalOpen(false)}/>
                        </div>
                    </div>
                </CustomModal>

                <div className={s.title}>
                    KYC for Business Account
                </div>

                <Formik
                    initialValues={{
                        // @ts-ignore
                        kycId: newKycList[0].id,
                        companyName: owner,
                        // @ts-ignore
                        country: newKycList[0].country,
                        // @ts-ignore
                        city: newKycList[0].city,
                        // @ts-ignore
                        address: newKycList[0].address,
                        // @ts-ignore
                        email: newKycList[0].email,
                        // @ts-ignore
                        postalCode: newKycList[0].postalCode,
                        // @ts-ignore
                        companyType: newKycList[0].companyType,
                        // @ts-ignore
                        natureOfBusiness: newKycList[0].natureOfBusiness,
                        // @ts-ignore
                        registrationNumber: newKycList[0].registrationNumber,
                        // @ts-ignore
                        financialRegulator: newKycList[0].financialRegulator,
                        // @ts-ignore
                        regulatoryLicenseNumber: newKycList[0].regulatoryLicenseNumber,
                        // @ts-ignore
                        website: newKycList[0].website,
                        // @ts-ignore
                        marketingStrategy: newKycList[0].marketingStrategy,
                        // @ts-ignore
                        industryId: newKycList[0].industryId,
                        // @ts-ignore
                        taxNumber: newKycList[0].taxNumber,
                        // @ts-ignore
                        currencies: newKycList[0].currencies,
                        // @ts-ignore
                        sourcesOfWealth: newKycList[0].sourcesOfWealth || [],
                        // @ts-ignore
                        isOtherSourcesOfWealth: newKycList[0].isOtherSourcesOfWealth || false,
                        // @ts-ignore
                        otherSourcesOfWealth: newKycList[0].otherSourcesOfWealth || '',
                        phoneNumber: phoneNumber[1],
                        // @ts-ignore
                        firstName: newKycList[0].firstName,
                        // @ts-ignore
                        lastName: newKycList[0].lastName,
                        // @ts-ignore
                        residenceCountryName: newKycList[0].authorizedPersonCountryOfResidence,
                        // @ts-ignore
                        authorizedCitizenshipName: newKycList[0].citizenship,
                        // @ts-ignore
                        authorizedPersonCity: newKycList[0].authorizedPersonCity,
                        // @ts-ignore
                        authorizedPersonAddress: newKycList[0].authorizedPersonAddress,
                        // @ts-ignore
                        authorizedPersonPostalCode: newKycList[0].authorizedPersonPostalCode,
                        files: {
                            accountApplication: '',
                            articlesMemorandums: '',
                            proofOfAddress: '',
                            registrationCertificate: '',
                            beneficiaryIdDocument: '',
                            beneficiaryIdcp: '',
                            beneficiaryProofOfAddress: '',
                            registerOfDirectorOrMembers: '',
                            registerOfShareholders: '',
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        let tempData = {
                            ...values,
                            registrationDate: date.from,
                            dateOfBirth: date.birth,
                            country: getCountryCode(values.country ? values.country : ''),
                            authorizedPersonCountryOfResidence: getCountryCode(values.residenceCountryName ? values.residenceCountryName : ''),
                            citizenship: getCountryCode(values.authorizedCitizenshipName ? values.authorizedCitizenshipName : ''),
                            files: {}
                        }

                        if (tempData.isOtherSourcesOfWealth) {
                            tempData.sourcesOfWealth = []
                            // @ts-ignore
                            tempData.sourcesOfWealth.push(tempData.otherSourcesOfWealth)
                        }

                        // @ts-ignore
                        delete tempData.otherSourcesOfWealth
                        // @ts-ignore
                        delete tempData.isOtherSourcesOfWealth

                        let convertedAccountApplication = await toBase64(documents.accountApplication) as string
                        let convertedarticlesMemorandums = await toBase64(documents.articlesMemorandums) as string
                        let convertedProofOfAddress = await toBase64(documents.proofOfAddress) as string
                        let convertedRegistrationCertificate = await toBase64(documents.registrationCertificate) as string
                        let convertedBeneficiaryIdDocument = await toBase64(documents.beneficiaryIdDocument) as string
                        let convertedBeneficiaryIdcp = await toBase64(documents.beneficiaryIdcp) as string
                        let convertedBeneficiaryProofOfAddress = await toBase64(documents.beneficiaryProofOfAddress) as string
                        let convertedRegisterOfDirectorOrMembers = await toBase64(documents.registerOfDirectorOrMembers) as string
                        let convertedRegisterOfShareholders = await toBase64(documents.registerOfShareholders) as string

                        if (convertedBeneficiaryProofOfAddress) {
                            tempData.files = {
                                ...tempData.files,
                                beneficiaryProofOfAddress: convertedBeneficiaryProofOfAddress,
                            }
                        }

                        if (convertedRegisterOfDirectorOrMembers) {
                            tempData.files = {
                                ...tempData.files,
                                registerOfDirectorOrMembers: convertedRegisterOfDirectorOrMembers,
                            }
                        }

                        if (convertedRegisterOfShareholders) {
                            tempData.files = {
                                ...tempData.files,
                                registerOfShareholders: convertedRegisterOfShareholders,
                            }
                        }

                        if (convertedBeneficiaryIdcp) {
                            tempData.files = {
                                ...tempData.files,
                                beneficiaryIdcp: convertedBeneficiaryIdcp,
                            }
                        }

                        if (convertedBeneficiaryIdDocument) {
                            tempData.files = {
                                ...tempData.files,
                                beneficiaryIdDocument: convertedBeneficiaryIdDocument,
                            }
                        }

                        if (convertedRegistrationCertificate) {
                            tempData.files = {
                                ...tempData.files,
                                registrationCertificate: convertedRegistrationCertificate,
                            }
                        }

                        if (convertedProofOfAddress) {
                            tempData.files = {
                                ...tempData.files,
                                proofOfAddress: convertedProofOfAddress,
                            }
                        }

                        if (convertedAccountApplication) {
                            tempData.files = {
                                ...tempData.files,
                                accountApplication: convertedAccountApplication,
                            }
                        }

                        if (convertedarticlesMemorandums) {
                            tempData.files = {
                                ...tempData.files,
                                articlesMemorandums: convertedarticlesMemorandums,
                            }
                        }

                        if (documents.poA !== '') {
                            // @ts-ignore
                            let convertedPoA = await toBase64(documents.poA) as string

                            if (convertedPoA) {
                                tempData.files = {
                                    ...tempData.files,
                                    poA: convertedPoA,
                                }
                            }
                        }

                        if (documents.license !== '') {
                            // @ts-ignore
                            let convertedLicense = await toBase64(documents.license) as string

                            if (convertedLicense) {
                                tempData.files = {
                                    ...tempData.files,
                                    license: convertedLicense,
                                }
                            }
                        }

                        if (documents.idCard !== '') {
                            // @ts-ignore
                            let convertedId = await toBase64(documents.idCard) as string

                            if (convertedId) {
                                tempData.files = {
                                    ...tempData.files,
                                    idCard: convertedId,
                                }
                            }
                        }

                        if (documents.backOfIdCard !== '') {
                            // @ts-ignore
                            let convertedBackOfId = await toBase64(documents.backOfIdCard) as string

                            if (convertedBackOfId) {
                                tempData.files = {
                                    ...tempData.files,
                                    backOfIdCard: convertedBackOfId,
                                }
                            }
                        }

                        dispatch(editKycThunk(token, tempData))
                            .then((res) => {
                                if (res.data === "Updated") {
                                    setIsModalOpen(true)
                                }
                            })
                            .catch((error) => {
                                if (error.response.data === "Invalid authorization data") {
                                    dispatch(setTokenState(false))
                                    return
                                }

                                if (error.response.data === "Too many phones. Max count is 5") {
                                    setErrorMessage(error.response.data)
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.response.data === "Phone already exist") {
                                    setErrorMessage("This phone number already exists. Please try another phone number.")
                                    setIsErrorModalOpen(true)
                                    return
                                }

                                if (error.code === "ERR_NETWORK" && !error.response) {
                                    setErrorMessage("Maximum files size is 90 MB")
                                    setIsErrorModalOpen(true)
                                } else {
                                    setErrorMessage("Something went wrong. Please try again later.")
                                    setIsErrorModalOpen(true)
                                }
                            })
                            .finally(() => {
                                dispatch(setDisabled(false))
                            })
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit
                      }) => (

                        <form onSubmit={handleSubmit}>
                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Company name
                                    </div>
                                    <MyInput id='companyName'
                                             isError={errors.companyName}
                                             name='companyName'
                                             onChange={handleChange}
                                             value={values.companyName}
                                             touched={touched.companyName}
                                    />
                                    {errors.companyName &&
                                        <div className={s.error_message}>{errors.companyName}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Country
                                    </div>
                                    <SelectWithSearch
                                        id="country"
                                        name="country"
                                        isError={errors.country}
                                        items={countries}
                                    />
                                    {errors.country &&
                                        <div className={s.error_message}>{errors.country}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Phone number
                                    </div>
                                    <CustomDropdown
                                        items={getPhoneNumbersArr()}
                                        placeholder={"Choose your phone number"}
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        isError={errors.phoneNumber}
                                        touched={touched.phoneNumber}
                                    />
                                    {errors.phoneNumber &&
                                        <div className={s.error_message}>{errors.phoneNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * city
                                    </div>
                                    <MyInput id='city'
                                             name='city'
                                             onChange={handleChange}
                                             value={values.city}
                                             isError={errors.city}
                                             touched={touched.city}
                                    />
                                    {errors.city &&
                                        <div className={s.error_message}>{errors.city}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * street (address)
                                    </div>
                                    <MyInput id='address'
                                             name='address'
                                             onChange={handleChange}
                                             value={values.address}
                                             isError={errors.address}
                                             touched={touched.address}
                                    />
                                    {errors.address &&
                                        <div className={s.error_message}>{errors.address}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * post code
                                    </div>
                                    <MyInput id='postalCode'
                                             name='postalCode'
                                             onChange={handleChange}
                                             value={values.postalCode}
                                             isError={errors.postalCode}
                                             touched={touched.postalCode}
                                    />
                                    {errors.postalCode &&
                                        <div className={s.error_message}>{errors.postalCode}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * email
                                    </div>
                                    <MyInput id='email'
                                             name='email'
                                             onChange={handleChange}
                                             value={values.email}
                                             isError={errors.email}
                                             touched={touched.email}
                                    />
                                    {errors.email &&
                                        <div className={s.error_message}>{errors.email}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * company type
                                    </div>
                                    <CustomDropdown
                                        items={companyTypes}
                                        placeholder={"Choose company type"}
                                        id="companyType"
                                        name="companyType"
                                        isError={errors.companyType}
                                        touched={touched.companyType}
                                    />
                                    {errors.companyType &&
                                        <div className={s.error_message}>{errors.companyType}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        registration date
                                    </div>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.from)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, from: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, from: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>
                                </div>
                            </div>

                            {
                                values.companyType === "Financial Institution" &&
                                <div className={s.row}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * financial regulator
                                        </div>
                                        <MyInput id='financialRegulator'
                                                 name='financialRegulator'
                                                 onChange={handleChange}
                                                 value={values.financialRegulator}
                                                 isError={errors.financialRegulator}
                                                 touched={touched.financialRegulator}
                                        />
                                        {errors.financialRegulator &&
                                            <div className={s.error_message}>{errors.financialRegulator}</div>}
                                    </div>

                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * regulatory license number
                                        </div>
                                        <MyInput id='regulatoryLicenseNumber'
                                                 name='regulatoryLicenseNumber'
                                                 onChange={handleChange}
                                                 value={values.regulatoryLicenseNumber}
                                                 isError={errors.regulatoryLicenseNumber}
                                                 touched={touched.regulatoryLicenseNumber}
                                        />
                                        {errors.regulatoryLicenseNumber &&
                                            <div className={s.error_message}>{errors.regulatoryLicenseNumber}</div>}
                                    </div>
                                </div>
                            }

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Registration Number
                                    </div>
                                    <MyInput id='registrationNumber'
                                             name='registrationNumber'
                                             onChange={handleChange}
                                             value={values.registrationNumber}
                                             isError={errors.registrationNumber}
                                             touched={touched.registrationNumber}
                                    />
                                    {errors.registrationNumber &&
                                        <div className={s.error_message}>{errors.registrationNumber}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        Nature of Business
                                    </div>
                                    <CustomDropdown
                                        items={natureOfBusinessArray}
                                        placeholder={"Choose nature of business"}
                                        id="natureOfBusiness"
                                        name="natureOfBusiness"
                                        isError={errors.natureOfBusiness}
                                        touched={touched.natureOfBusiness}
                                    />
                                    {errors.natureOfBusiness &&
                                        <div className={s.error_message}>{errors.natureOfBusiness}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        ** website
                                    </div>
                                    <MyInput id='website'
                                             name='website'
                                             onChange={handleChange}
                                             value={values.website}
                                             isError={errors.website}
                                             touched={touched.website}
                                    />
                                    {errors.website &&
                                        <div className={s.error_message}>{errors.website}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        ** Marketing strategy
                                    </div>
                                    <MyInput id='marketingStrategy'
                                             name='marketingStrategy'
                                             onChange={handleChange}
                                             value={values.marketingStrategy}
                                             isError={errors.marketingStrategy}
                                             touched={touched.marketingStrategy}
                                    />
                                    {errors.marketingStrategy &&
                                        <div className={s.error_message}>{errors.marketingStrategy}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Industry Id
                                    </div>
                                    <MyInput id='industryId'
                                             name='industryId'
                                             onChange={handleChange}
                                             value={values.industryId}
                                             isError={errors.industryId}
                                             touched={touched.industryId}
                                    />
                                    {errors.industryId &&
                                        <div className={s.error_message}>{errors.industryId}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Tax Number
                                    </div>
                                    <MyInput id='taxNumber'
                                             name='taxNumber'
                                             onChange={handleChange}
                                             value={values.taxNumber}
                                             isError={errors.taxNumber}
                                             touched={touched.taxNumber}
                                    />
                                    {errors.taxNumber &&
                                        <div className={s.error_message}>{errors.taxNumber}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * AUTHORIZED PERSON FIRST NAME
                                    </div>
                                    <MyInput id='firstName'
                                             name='firstName'
                                             onChange={handleChange}
                                             value={values.firstName}
                                             isError={errors.firstName}
                                             touched={touched.firstName}
                                    />
                                    {errors.firstName && touched.firstName &&
                                        <div className={s.error_message}>{errors.firstName}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * AUTHORIZED PERSON LAST NAME
                                    </div>
                                    <MyInput id='lastName'
                                             name='lastName'
                                             onChange={handleChange}
                                             value={values.lastName}
                                             isError={errors.lastName}
                                             touched={touched.lastName}
                                    />
                                    {errors.lastName && touched.lastName &&
                                        <div className={s.error_message}>{errors.lastName}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * Date of Birth
                                    </div>
                                    <DatePicker suffixIcon={null} style={dateStyle}
                                                defaultValue={dayjs(new Date(), dateFormat)}
                                                value={dayjs(date.birth)}
                                                onChange={(_, dateString) => {
                                                    if (!dateString) {
                                                        setDate({...date, birth: new Date().toDateString()})
                                                    } else {
                                                        setDate({...date, birth: dateString})
                                                    }
                                                }}
                                                format={dateFormat}/>
                                </div>
                                <div className={s.input_block}>
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * AUTHORIZED PERSON COUNTRY OF RESIDENCE
                                        </div>
                                        <SelectWithSearch
                                            id="residenceCountryName"
                                            name="residenceCountryName"
                                            isError={errors.residenceCountryName}
                                            items={countries}
                                        />
                                        {errors.residenceCountryName &&
                                            <div className={s.error_message}>{errors.residenceCountryName}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * AUTHORIZED PERSON CITIZENSHIP
                                    </div>
                                    <SelectWithSearch
                                        id="authorizedCitizenshipName"
                                        name="authorizedCitizenshipName"
                                        isError={errors.authorizedCitizenshipName}
                                        items={countries}
                                    />
                                    {errors.authorizedCitizenshipName &&
                                        <div className={s.error_message}>{errors.authorizedCitizenshipName}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * AUTHORIZED PERSON CITY
                                    </div>
                                    <MyInput id='authorizedPersonCity'
                                             name='authorizedPersonCity'
                                             onChange={handleChange}
                                             value={values.authorizedPersonCity}
                                             isError={errors.authorizedPersonCity}
                                             touched={touched.authorizedPersonCity}
                                    />
                                    {errors.authorizedPersonCity && touched.authorizedPersonCity &&
                                        <div className={s.error_message}>{errors.authorizedPersonCity}</div>}
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * AUTHORIZED PERSON STREET (ADDRESS)
                                    </div>
                                    <MyInput id='authorizedPersonAddress'
                                             name='authorizedPersonAddress'
                                             onChange={handleChange}
                                             value={values.authorizedPersonAddress}
                                             isError={errors.authorizedPersonAddress}
                                             touched={touched.authorizedPersonAddress}
                                    />
                                    {errors.authorizedPersonAddress && touched.authorizedPersonAddress &&
                                        <div className={s.error_message}>{errors.authorizedPersonAddress}</div>}
                                </div>

                                <div className={s.input_block}>
                                    <div className={s.input_label}>
                                        * AUTHORIZED PERSON POSTAL CODE
                                    </div>
                                    <MyInput id='authorizedPersonPostalCode'
                                             name='authorizedPersonPostalCode'
                                             onChange={handleChange}
                                             value={values.authorizedPersonPostalCode}
                                             isError={errors.authorizedPersonPostalCode}
                                             touched={touched.authorizedPersonPostalCode}
                                    />
                                    {errors.authorizedPersonPostalCode && touched.authorizedPersonPostalCode &&
                                        <div className={s.error_message}>{errors.authorizedPersonPostalCode}</div>}
                                </div>
                            </div>


                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={s.input_label}>
                                        * Sources of wealth or income
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group"
                                         className={s.sources_of_wealth_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Investments")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Investments")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Investments</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Business ownership interests")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Business ownership interests")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Business ownership interests</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Employment income")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Employment income")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Employment income</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Personal savings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Personal savings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Personal savings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Pension releases")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Pension releases")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Pension releases</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Share sales and dividends")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Share sales and dividends")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Share sales and dividends</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Property sales")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Property sales")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Property sales</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Gambling winnings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Gambling winnings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Gambling winnings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Inheritances and gifts")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Inheritances and gifts")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Inheritances and gifts</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.sourcesOfWealth.includes("Compensation from legal rulings")}
                                                            onChange={(e) => {
                                                                sourcesOfWealthHandlerChange(e, props.meta, props.form, "Compensation from legal rulings")
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Compensation from legal rulings</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field
                                                render={(props: any) => {
                                                    return (
                                                        <Checkbox
                                                            checked={props.meta.value.isOtherSourcesOfWealth}
                                                            onChange={(e) => {
                                                                const {checked} = e.target;
                                                                if (checked) {
                                                                    props.form.setFieldValue("sourcesOfWealth", []);
                                                                }
                                                                props.form.setFieldValue(
                                                                    "isOtherSourcesOfWealth",
                                                                    checked
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <span className={s.checkbox_label}>Other</span>
                                        </label>
                                    </div>
                                    {errors.sourcesOfWealth && touched.sourcesOfWealth && (
                                        <div className={s.error_message}>
                                            {Array.isArray(errors.sourcesOfWealth)
                                                ? errors.sourcesOfWealth.join(', ')
                                                : String(errors.sourcesOfWealth)}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={s.row}>
                                {
                                    // @ts-ignore
                                    values.isOtherSourcesOfWealth &&
                                    <div className={s.input_block}>
                                        <div className={s.input_label}>
                                            * Other
                                        </div>
                                        <MyInput id='otherSourcesOfWealth'
                                                 name='otherSourcesOfWealth'
                                                 onChange={handleChange}
                                                 value={values.otherSourcesOfWealth}
                                                 isError={errors.otherSourcesOfWealth}
                                                 touched={touched.otherSourcesOfWealth}
                                        />
                                    </div>
                                }
                                <div className={s.input_block}>
                                </div>
                            </div>

                            <div className={s.row}>
                                <div className={classnames(s.input_block)}>
                                    <div className={classnames(s.input_label, errors.currencies && s.doc_error)}
                                         id="checkbox-group">
                                        * Add a new account
                                    </div>
                                    <div role="group" aria-labelledby="checkbox-group" className={s.checkbox_wrapper}>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CAD"/>
                                            <span className={s.checkbox_label}>CAD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="EUR"/>
                                            <span className={s.checkbox_label}>EUR</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="GBP"/>
                                            <span className={s.checkbox_label}>GBP</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USD"/>
                                            <span className={s.checkbox_label}>USD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="USDT"/>
                                            <span className={s.checkbox_label}>USDT</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="SGD"/>
                                            <span className={s.checkbox_label}>SGD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="HKD"/>
                                            <span className={s.checkbox_label}>HKD</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="JPY"/>
                                            <span className={s.checkbox_label}>JPY</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="CNH"/>
                                            <span className={s.checkbox_label}>CNH</span>
                                        </label>
                                        <label className={s.checkbox_block}>
                                            <Field as={Checkbox} type="checkbox" name="currencies" value="AED"/>
                                            <span className={s.checkbox_label}>AED</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={legal_account_application} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.accountApplication === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Legal Account Application
                                        </div>
                                        <div>
                                            <div className={s.passport_spread_list}>
                                                <div>
                                        <span className={classnames(s.flex, s.text_color)}>
                                           1. <img src={download_legal} alt=""/>
                                            <a href="/Legal_Account_Application_form.pdf" target={"_blank"} className={s.download_link}>
                                                Download legal account application
                                            </a>
                                        </span>
                                                </div>
                                                <div>
                                        <span className={s.text_color}>
                                             2. Fill in relevant information.
                                        </span>
                                                </div>
                                                <div>
                                        <span className={s.text_color}>
                                            3. Upload filled and signed legal account application form.
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'accountApplication'}
                                    inputId={'accountApplication'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"accountApplication"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'account_application'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            accountApplication: event.currentTarget.files[0]
                                        });
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, accountApplication: ""})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={proofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.proofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Company Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the property
                                            (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'proofOfAddress'}
                                    inputId={'proofOfAddress'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"proofOfAddress"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'proof_of_Address'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            proofOfAddress: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, proofOfAddress: ''})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={companyCharter} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.articlesMemorandums === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Articles & Memorandums of Association
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload company Charter/Statute/Memorandum/Articles of Association/Memorandum
                                            of
                                            Association
                                            or any other relevant statutory documents.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'articlesMemorandums'}
                                    inputId={'articlesMemorandums'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"articlesMemorandums"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'articles_&_memorandums'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            articlesMemorandums: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, articlesMemorandums: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={registrationSertificate} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.registrationCertificate === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Registration Certificate
                                        </div>
                                        <div className={s.add_block_text}>
                                            Upload Certificate of Incorporation or Certificate of enterprise
                                            registration.
                                        </div>
                                        {/*<div className={s.add_block_text}>*/}
                                        {/*    You can paste a link to government website with company information.*/}
                                        {/*</div>*/}
                                        {/*<div className={s.input_block}>*/}
                                        {/*    <div className={s.input_label}>*/}
                                        {/*        Link*/}
                                        {/*    </div>*/}
                                        {/*    <MyInput/>*/}
                                        {/*</div>*/}

                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'registrationCertificate'}
                                    inputId={'registrationCertificate'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"registrationCertificate"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'registration_certificate'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            registrationCertificate: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, registrationCertificate: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdDoc} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdDocument === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Identity document (Passport, ID Card, Driving license)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Good photo tips:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            A color photo contains full document in clear focus and the data can be read easily.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             There're no light glares impeding document readability.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             A photo was not edited in graphic applications (we will definitely check).
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Picture format: JPEG, JPG, PNG, PDF.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Minimum picture resolution: 800 x 600 pixels.
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Maximum file size: 8Mb.
                                        </span>
                                            </li>
                                            <div className={s.attention}>
                                                Please upload Identity document (Passport, ID Card, Driving license)
                                                photos
                                                of each
                                                Shareholder with more than 25% ownership, all Board Members and
                                                Directors.
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'beneficiaryIdDocument'}
                                    inputId={'beneficiaryIdDocument'}
                                    allowedTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                    identifier={"beneficiaryIdDocument"}
                                    maxFileSize={8}
                                    kycList={newKycList}
                                    documentTitle={'beneficiary_id_document'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            beneficiaryIdDocument: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, beneficiaryIdDocument: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={beneficiaryIdcp} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryIdcp === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary IDCP
                                        </div>
                                        <div className={s.add_block_text} style={{marginBottom: '16px'}}>
                                            Upload an ID confirmation photo (selfie of the Beneficiary holding in his
                                            hands
                                            his ID
                                            and a paper with written name of platform "{brandConfig.companyNameSimple}", date (dd/mm/yyyy/)
                                            and
                                            Beneficiary's
                                            signature)
                                        </div>
                                        <div className={s.attention}>
                                            Please upload Identity document (Passport, ID Card, Driving license) photos
                                            of
                                            each
                                            Shareholder with more than 25% ownership, all Board Members and Directors.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'beneficiaryIdcp'}
                                    inputId={'beneficiaryIdcp'}
                                    allowedTypes={['image/png', 'image/jpeg', 'application/pdf']}
                                    identifier={"beneficiaryIdcp"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'beneficiary_idcp'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            beneficiaryIdcp: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, beneficiaryIdcp: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={BeneficiaryProofOfAddress} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.beneficiaryProofOfAddress === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Beneficiary Proof of Address
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Acceptable documents are:
                                        </div>
                                        <ul className={s.passport_spread_list}>
                                            <li>
                                        <span className={s.text_color}>
                                            Bank statement with the date of issue and the name of the person (the document
                                            must be not older than 3 months);
                                        </span>
                                            </li>
                                            <li>
                                        <span className={s.text_color}>
                                             Utility bill for gas, electricity, water, internet, etc. linked to the
                                            property (the document must not be older than 3 months).
                                        </span>
                                            </li>
                                        </ul>
                                        {/*<div className={s.attention}>*/}
                                        {/*    Please upload Identity document (Passport, ID Card, Driving license) photos*/}
                                        {/*    of*/}
                                        {/*    each*/}
                                        {/*    Shareholder with more than 25% ownership, all Board Members and Directors.*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'beneficiaryProofOfAddress'}
                                    inputId={'beneficiaryProofOfAddress'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"beneficiaryProofOfAddress"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'beneficiary_proof_of_address'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            beneficiaryProofOfAddress: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, beneficiaryProofOfAddress: null})
                                    }}
                                />

                            </div>

                             <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={RegisterDirector} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.registerOfDirectorOrMembers === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Register of Director/Members
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Upload Register of Director/Members
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'registerOfDirectorOrMembers'}
                                    inputId={'registerOfDirectorOrMembers'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"registerOfDirectorOrMembers"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'register_of_director_or_members'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            registerOfDirectorOrMembers: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, registerOfDirectorOrMembers: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={RegisterShareholder} alt=""/>
                                    </div>
                                    <div>
                                        <div
                                            className={classnames(s.add_doc_title, docsError && documents.registerOfShareholders === '' && s.doc_error)}>
                                            <span className={s.required}>* </span>
                                            Register of Shareholders
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            Upload Register of Shareholders
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'registerOfShareholders'}
                                    inputId={'registerOfShareholders'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"registerOfShareholders"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'register_of_shareholders'}
                                    onChange={(event) => {
                                        setDocuments({
                                            ...documents,
                                            // @ts-ignore
                                            registerOfShareholders: event.currentTarget.files[0]
                                        })
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, registerOfShareholders: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={Poa} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            PoA (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If the company is represented by a person not authorized to do so by the
                                            company’s
                                            Articles of Association or equivalent document, please upload a Power of
                                            Attorney
                                            asserting the right of this person to represent company's interests.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'poA'}
                                    inputId={'poA'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"poA"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'poA'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, poA: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, poA: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={license} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            Licence (If applicable)
                                        </div>
                                        <div className={s.add_doc_subtitle}>
                                            If your business requires a license in order to operate, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'license'}
                                    inputId={'license'}
                                    allowedTypes={['application/pdf']}
                                    identifier={"license"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'license'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, license: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, license: null})
                                    }}
                                />
                                <div className={s.attached_file_wrapper}>
                                    <div className={s.btn_block}>
                                        <TransparentButton
                                            icon={<Icon component={() => <Plus/>} rev={undefined}/>}
                                            title={"Add file"}
                                            onClick={(e: any) => {
                                                e.preventDefault()
                                                // @ts-ignore
                                                document.getElementById('license').click()
                                            }}
                                        />
                                        <input type="file"
                                               accept="application/pdf"
                                               id={"license"}
                                               className={s.hidden_input}
                                               onChange={(event) => {
                                                   // @ts-ignore
                                                   setDocuments({...documents, license: event.currentTarget.files[0]})
                                               }}
                                        />
                                    </div>
                                    <div className={s.attached_file}>

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "license"
                                                && documents.license === ''
                                                && <img key={index} src={rejectedDoc} alt=""/>)
                                        }

                                        {
                                            documents?.license?.name && <img src={attachedDoc} alt=""/>
                                        }

                                        {documents?.license?.name}

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && item.fileType === "license"
                                                && documents.license === ''
                                                && <span key={index} className={s.rejected_doc_name}>license</span>)
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "New"
                                                && item.fileType === "license"
                                                && documents.license === ''
                                                && <span key={index} className={s.approved_doc_name}>license</span>)
                                        }

                                        {
                                            documents?.license?.name &&
                                            <img onClick={() => {
                                                setDocuments({...documents, license: null})
                                            }}
                                                 className={s.delete_doc}
                                                 src={delete_document}
                                                 alt=""
                                            />
                                        }

                                        {
                                            // @ts-ignore
                                            newKycList[0].files.map((item, index) => item.status === "Rejected"
                                                && documents.license === ''
                                                && item.fileType === "license"
                                                && <img className={s.delete_doc}
                                                        src={delete_rejected_document}
                                                        alt=""
                                                        key={index}
                                                />)

                                        }
                                    </div>
                                </div>

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={idCard} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            ID Card/Residence Permit Card
                                        </div>
                                        <div className={s.add_block_text}>
                                            If you have National ID Card or Residence Permit Card, please upload it.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenIdCardInput'}
                                    inputId={'hiddenIdCardInput'}
                                    allowedTypes={['image/png', 'image/jpeg', '.pdf']}
                                    identifier={"idCard"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'idCard'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, idCard: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, idCard: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.add_doc_block}>
                                <div className={s.content_block}>
                                    <div>
                                        <img src={idCard} alt=""/>
                                    </div>
                                    <div>
                                        <div className={s.add_doc_title}>
                                            Back of ID Card/Residence Permit Card
                                        </div>
                                        <div className={s.add_block_text}>
                                            If you uploaded ID Card/Driving license, please upload the back side of it.
                                        </div>
                                    </div>
                                </div>
                                <FileInput 
                                    s={s}
                                    documents={documents}
                                    key={'hiddenBackOfIdCardInput'}
                                    inputId={'hiddenBackOfIdCardInput'}
                                    allowedTypes={['image/png', 'image/jpeg', '.pdf']}
                                    identifier={"backOfIdCard"}
                                    maxFileSize={10}
                                    kycList={newKycList}
                                    documentTitle={'backOfIdCard'}
                                    onChange={(event) => {
                                        // @ts-ignore
                                        setDocuments({...documents, backOfIdCard: event.currentTarget.files[0]})
                                    }}
                                    onImageClick={() => {
                                        setDocuments({...documents, backOfIdCard: null})
                                    }}
                                />

                            </div>

                            <div className={s.line}></div>

                            <div className={s.required_fields}>
                                * required fields.
                            </div>

                            <div className={s.btn_block}>
                                <div className={s.button_back_wrapper}>
                                    <TransparentButton
                                        icon={<Icon component={() => <Back/>} rev={undefined}/>}
                                        title={"Back"}
                                        type={"button"}
                                        onClick={() => props.setCurrent(0)}/>
                                </div>
                                <div className={s.button_apply_wrapper}>
                                    <MyBtn title={'Apply'} type="submit"/>
                                </div>
                            </div>

                        </form>
                    )}
                </Formik>
            </div>
        </motion.div>
    );
};

export default EditKyc;
