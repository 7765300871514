import React, {useEffect, useState} from 'react';
import style from './header.module.css'
import TransparentButton from "../ui/TransparentButton/TransparentButton";
import {Link, useLocation, useNavigate} from "react-router-dom";
import logo from '../../assets/newImg/publicPages/logo.svg'
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown";
import {ABOUT, AGENT_PROGRAM, CONTACTS, FAQ, WHY_US} from "../../Constants/RoutesConstants";
import {useAppSelector} from "../../hooks/redux";
import s from "../../pages/PersonalAccount/PersonalAccount.module.css";

const Header = () => {

    const navigate = useNavigate()
    const isLogin = useAppSelector(state => state.userReducer.user.userToken)
    const firstName: string | null = useAppSelector(state => state.userReducer.user.userData.firstName)
    const lastName: string | null = useAppSelector(state => state.userReducer.user.userData.lastName)
    const companyName: string | null = useAppSelector(state => state.userReducer.user.userData.companyName)

    const location = useLocation()

    const [isHeaderShow, setIsHeaderShow] = useState(true)

    useEffect(() => {

        if (
            location.pathname.includes("login") ||
            location.pathname.includes("sign_up") ||
            location.pathname.includes("personal_account")
            ) {
            setIsHeaderShow(false)
        } else {
            setIsHeaderShow(true)
        }
    },[location.pathname])

    return (
        <>
            {isHeaderShow && <header className={style.container}>
                <div className={style.block}>
                    <Link className={style.header_link} to="/">
                        <img src={logo} alt="" className={"logo"} width={168} height={40}/>
                    </Link>
                    <Link className={style.header_link} to="/">Home</Link>
                    <Link className={style.header_link} to={ABOUT}>About Us</Link>
                    <HeaderDropdown/>
                    <Link className={style.header_link} to={WHY_US}>Why Us</Link>
                    <Link className={style.header_link} to={FAQ}>FAQ</Link>
                    <Link className={style.header_link} to={AGENT_PROGRAM}>Agent program</Link>
                    <Link className={style.header_link} to={CONTACTS}>Contacts</Link>
                </div>
                <div>
                    {
                        !!isLogin
                            ? <div className={s.user_round} onClick={() => navigate('/personal_account/accounts')}>
                                { firstName?.slice(0,1)}{lastName?.slice(0,1)}{companyName?.slice(0,1)}
                              </div>
                            : <TransparentButton title={"Log In"} onClick={() => navigate('/login')} small/>
                    }
                </div>
            </header>}
        </>

    );
};

export default Header;
