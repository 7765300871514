import React, {Dispatch, SetStateAction, useEffect, useState, useRef} from 'react'
import {Checkbox, ConfigProvider, DatePicker} from "antd";
import s from "./StatementForm.module.css";
import dayjs from "dayjs";
import MyInput from "../../../../../components/ui/MyInput/MyInput";
import MyBtn from "../../../../../components/ui/MyBtn/MyBtn";
import {Formik, useFormikContext} from "formik";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import {setFilters} from "../../../../../store/reducers/TransactionsSlice/TransactionsSlice";
import {useAppDispatch} from "../../../../../hooks/redux";
import CustomDropdownForAccounts
    from "../../../../../components/ui/CustomDropdownForAccounts/CustomDropdownForAccounts";
import CustomDropdown from "../../../../../components/ui/CustomDropdown/CustomDropdown";
import {
    getFirstDayOfCurrentMonth, getFirstDayOfLastWeek, getFirstDayOfPreviousMonth,
    getFirstWeekDay,
    getLastDayOfCurrentMonth, getLastDayOfLastWeek, getLastDayOfPreviousMonth,
    getLastWeekDay,
    myDate
} from "../../../../../helpers/CalculateDate";
import * as yup from "yup";
import {useAccountsForSelect} from "../../../../../hooks/useAccountsForSelect";
dayjs.extend(weekday)
dayjs.extend(localeData)

type propsType = {
    setDataForStatement: Dispatch<SetStateAction<any>>
}

const optionsTwo: string[] = [
    'Indicated by user',
    'Today',
    'Yesterday',
    'This week',
    'This month',
    'Last week',
    'Last 30 days',
    'Last month',
    'Last and current month',
];

const dateFormat = 'YYYY-MM-DD';

const SetDateByPeriod = (props: any) => {

    const { values } = useFormikContext<any>();

    const { setDate, date } = props;

    // const previousValues = useRef({ date, period: values.period });
    const previousValues = useRef<{ period: string; date: { from: string; to: string } }>({
        period: '',
        date: { from: '', to: '' }
    });

    useEffect(() => {
        // Only update if the period has changed or if the date needs to be updated
        if (previousValues.current.period === values.period &&
            previousValues.current.date.from === date.from &&
            previousValues.current.date.to === date.to) {
            return;
        }

        const myDate = new Date();

        // Helper function to format date
        const getFormattedDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

        let newDate = { ...date };

        switch (values.period) {
            case 'Today':
                newDate = {
                    from: getFormattedDate(myDate),
                    to: getFormattedDate(myDate)
                };
                break;
            case 'Yesterday':
                myDate.setDate(myDate.getDate() - 1);
                newDate = {
                    from: getFormattedDate(myDate),
                    to: getFormattedDate(myDate)
                };
                break;
            case 'This week':
                newDate = {
                    from: getFormattedDate(getFirstWeekDay()),
                    to: getFormattedDate(getLastWeekDay())
                };
                break;
            case 'This month':
                newDate = {
                    from: getFormattedDate(getFirstDayOfCurrentMonth()),
                    to: getFormattedDate(getLastDayOfCurrentMonth())
                };
                break;
            case 'Last week':
                newDate = {
                    from: getFormattedDate(getFirstDayOfLastWeek()),
                    to: getFormattedDate(getLastDayOfLastWeek())
                };
                break;
            case 'Last 30 days':
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
                newDate = {
                    from: getFormattedDate(thirtyDaysAgo),
                    to: getFormattedDate(myDate)
                };
                break;
            case 'Last month':
                newDate = {
                    from: getFormattedDate(getFirstDayOfPreviousMonth()),
                    to: getFormattedDate(getLastDayOfPreviousMonth())
                };
                break;
            case 'Last and current month':
                newDate = {
                    from: getFormattedDate(getFirstDayOfPreviousMonth()),
                    to: getFormattedDate(getLastDayOfCurrentMonth())
                };
                break;
            default:
                break;
        }

        setDate(newDate);
        previousValues.current = { period: values.period, date: newDate };
    }, [values.period, date, setDate]);

    return null;
};

const validationSchema = yup.object({
    recipient: yup.string().max(20, 'max 20 symbols'),
    amount: yup.string()
        .matches(/^[-+]?[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$/, "Must be only digits").max(20, 'max 20 digits'),
})

export const StatementForm = (props: propsType) => {

    const dispatch = useAppDispatch()
    const accountsList = useAccountsForSelect()
    const [isIncoming, setIsIncoming] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const [isOutgoing, setIsOutgoing] = useState(false)
    const [currency, setCurrency] = useState('')
    const [date, setDate] = useState({
        from: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate()),
        to: (myDate.getFullYear() + "-" + (myDate.getMonth() + 1 < 10 ?  ("0" + (myDate.getMonth() + 1)).slice(-2) : myDate.getMonth() + 1) + "-" + myDate.getDate())
    })

    useEffect(() => {
        return () => {
            dispatch(setFilters({
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                selectedType: '',
                templateName: ''
            }))
        }
    }, [dispatch])

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    },[])

    const dateStyle = {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2A2E37',
        padding: '17px 16px',
        height: 52,
        width: isMobile ? "46%" : 146,
        border: '1.5px solid #EEEFEF',
        borderRadius: 8
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    DatePicker: {
                        colorPrimary: '#2046A1',
                        fontWeightStrong: 600,
                        colorLink: 'red'
                    }
                },
                token: {
                    colorPrimary: '#2046A1',
                    colorBorder: '#EEEFEF',
                    fontFamily: 'Inter',
                },
            }}
        >
            <Formik
                initialValues={{
                    accountNumber: '',
                    period: '',
                    from: '',
                    to: '',
                    recipient: '',
                    amount: '',
                    incoming: false,
                    outgoing: false,
                    currency: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const tempData = {
                        ...values,
                        from: date.from,
                        to: date.to,
                        incoming: isIncoming,
                        outgoing: isOutgoing,
                        currency: currency
                    }
                    props.setDataForStatement(tempData)
                    dispatch(setFilters(tempData))
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                  }) => (
                    <form className={s.form} onSubmit={handleSubmit}>
                        <div className={s.dropdown_wrapper}>
                            <p className={s.labelText}>Account Number</p>
                            <CustomDropdownForAccounts
                                setCurrency={setCurrency}
                                items={accountsList}
                                id={'accountNumber'}
                                name={'accountNumber'}
                            />
                        </div>

                        <div className={s.periodRow}>
                            <div className={s.dropdown_wrapper}>
                                <p className={s.labelText}>Period</p>
                                <CustomDropdown
                                    items={optionsTwo}
                                    id={'period'}
                                    name={'period'}
                                />
                            </div>
                            <DatePicker suffixIcon={null} style={dateStyle}
                                        defaultValue={dayjs(new Date(), dateFormat)}
                                        value={dayjs(date.from)}
                                        format={dateFormat}
                                        name='dateFrom'
                                        onChange={(_, dateString) => {
                                            if (!dateString) {
                                                setDate({...date, from: new Date().toDateString()})
                                            } else {
                                                setDate({...date, from: dateString})
                                            }
                                            handleChange(values.period = 'Indicated by user')

                                        }}
                            />
                            <DatePicker suffixIcon={null} style={dateStyle}
                                        defaultValue={dayjs(new Date(), dateFormat)}
                                        value={dayjs(date.to)}
                                        format={dateFormat}
                                        name='dateTo'
                                        onChange={(_, dateString) => {
                                            if (!dateString) {
                                                setDate({...date, to: new Date().toDateString()})
                                            } else {
                                                setDate({...date, to: dateString})
                                            }
                                            handleChange(values.period = 'Indicated by user')
                                        }}
                            />
                        </div>
                        <div className={s.recipientAmount}>
                            <div className={s.inputWrapper}>
                                <p className={s.labelText}>Recipient</p>
                                <MyInput
                                    id={'recipient'}
                                    name={'recipient'}
                                    onChange={handleChange}
                                    value={values.recipient}
                                    isError={errors.recipient}
                                />
                                {errors.recipient &&
                                    <div className={s.error_message}>{errors.recipient}</div>}
                            </div>
                            <div className={s.inputWrapper}>
                                <p className={s.labelText}>Amount</p>
                                <MyInput
                                    id={'amount'}
                                    name={'amount'}
                                    onChange={handleChange}
                                    value={values.amount}
                                    isError={errors.amount}
                                />
                                {errors.amount &&
                                    <div className={s.error_message}>{errors.amount}</div>}
                            </div>
                        </div>
                        <div className={s.transactionsBlock}>
                            <p className={s.labelText}>Transactions</p>
                            <div className={s.checkboxWrapper}>
                                <Checkbox
                                    className={s.checkbox}
                                    checked={isIncoming}
                                    onChange={() => setIsIncoming(prevState => !prevState)}
                                    name='checkIncoming'
                                >
                                    Incoming
                                </Checkbox>
                                <Checkbox
                                    className={s.checkbox}
                                    checked={isOutgoing}
                                    onChange={() => setIsOutgoing(prevState => !prevState)}
                                    name='checkOutgoing'
                                >
                                    Outgoing
                                </Checkbox>
                            </div>
                        </div>
                        <div className={s.buttonWrapper}>
                            <MyBtn type={'submit'} style={{maxWidth: 180}} title={'Show'}/>
                        </div>
                        <SetDateByPeriod
                            values={values}
                            date={date}
                            setDate={setDate}
                        />
                    </form>)}
            </Formik>
        </ConfigProvider>
    )
}
