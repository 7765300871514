import axios from "axios";
import {
    LoginDataType,
    RegistrationDataType,
} from "../store/reducers/ActionCreators";
import {EmailVereficationDataType} from "../components/Autentications/EmailAutentication/EmailAuntification";
import {IAccounts, IContactData, ITransacton} from "../types/interfaces";
import {IExchangeBody} from "../pages/PersonalAccount/Currency/CurrencyExchange/ExchangeStepTwo/ExchangeStepTwo";

let baseSettings = {
    headers: {
        'Content-Type': 'application/json',
    },
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...baseSettings
}) as any

export const Api = {
    login(data: LoginDataType) {
        return instance.post('api/login/additionals/list', data)
    },
    finalLogin(data: LoginDataType) {
        return instance.post('api/login', data)
    },
    registration (data: RegistrationDataType) {
        return instance.post('api/registrations', data)
    },
    sendPersonalKyc (token: string, data: any) {
        return instance.post('api/kyc/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getKycList (token: string) {
        return instance.get('api/kyc/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteKyc (token: string, id: number) {
        return instance.post('api/kyc/delete', {kycId: id} ,{headers: {Authorization: `Bearer ${token}`}})
    },
    updateKyc (token: string, data:any) {
        return instance.post('api/kyc/update', data ,{headers: {Authorization: `Bearer ${token}`}})
    },
    getTransactionsList (token: string) {
        return instance.get('api/transaction/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    emailVerefication(data: EmailVereficationDataType) {
        return instance.post('api/verifies/emails ', data)
    },
    createAccounts(token: string, accounts: IAccounts) {
        return instance.post('/api/account/create', accounts  ,{headers: {Authorization: `Bearer ${token}`}})
    },
    getAccountsList (token: string) {
        return instance.get('api/account/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    get2Fa (token: string) {
        return instance.get('api/2fa/create', {headers: {Authorization: `Bearer ${token}`}})
    },
    send2FaCode (token: string,data: any) {
        return instance.post('api/2fa/verify', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendTransfer (token: string,data: any) {
        return instance.post('/api/transaction/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    createTemplate (token: string, data: ITransacton) {
        return instance.post('/api/payment-template/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getTemplateList (token: string) {
        return instance.get('/api/payment-template/list', {headers: {Authorization: `Bearer ${token}`}})
    },
    getTemplateById (token: string, id: string | number | undefined) {
        return instance.get(`/api/payment-template/item/${id}`, {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteTemplate (token: string, id: number) {
        return instance.post(`/api/payment-template/delete`, {id: id}, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendExchange ( body: IExchangeBody) {
        return instance.post(`/api/converter/convert`, body)
    },
    sendExchangeRevert ( body: IExchangeBody) {
        return instance.post(`/api/converter/reverse-convert`, body)
    },
    sendCardRequest (token: string,data: any) {
        return instance.post('/api/card/create', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteOldCardRequest (token: string,data: any) {
        return instance.post('/api/card/request-new', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendPhysicalCardRequest (token: string,data: any) {
        return instance.post('/api/card/request-physical', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getCardInfo (token: string) {
        return instance.get('/api/card/getDetails', {headers: {Authorization: `Bearer ${token}`}})
    },
    sendCardTopUp (token: string,data: any) {
        return instance.post('/api/card/top-up', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getCardTransactionList (token: string, san: string, year: string, month: string) {
        return instance.get('/api/card/getTransactions', {params: {
                year: year,
                month: month,
                san: san,
            },headers: {Authorization: `Bearer ${token}`}})
    },
    activatePhysicalCard (token: string,data: any) {
        return instance.post('/api/card/activate-physical', data, {headers: {Authorization: `Bearer ${token}`}})
    },
   updateCard (token: string,data: any) {
        return instance.post('/api/card/update-card-holder', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    suspendCard (token: string,data: any) {
        return instance.post('/api/card/suspend', data, {headers: {Authorization: `Bearer ${token}`}})
    },
    resetPassword (data: any) {
        return instance.post(`/api/user/password/refresh`, data)
    },
    createNewPassword (token: string, data: any) {
        return instance.post(`api/user/password/change`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getTopUpInfo (token: string, data: any) {
        return instance.post(`api/top-up`, data, {headers: {Authorization: `Bearer ${token}`}})
    },
    getPhoneList (token: string) {
        return instance.get(`api/phone/list`, {headers: {Authorization: `Bearer ${token}`}})
    },
    addNewPhone (token: string, data: string) {
        return instance.post(`api/phone/create`, data,{headers: {Authorization: `Bearer ${token}`}})
    },
    getCodeForVerifyPhone (token: string, data: string) {
        return instance.post(`api/phone/send/verify/code`, {phoneId: data},{headers: {Authorization: `Bearer ${token}`}})
    },
    sendVerifyCode (token: string, phoneId: string, code: string) {
        return instance.post(`api/phone/verify`, {phoneId: phoneId, code: code},{headers: {Authorization: `Bearer ${token}`}})
    },
    deletePhone (token: string, phoneId: number | null) {
        return instance.post(`api/phone/delete`, {phoneId: phoneId}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setMainPhone (token: string, phoneId: number | null) {
        return instance.post(`api/phone/set-main`, {phoneId: phoneId}, {headers: {Authorization: `Bearer ${token}`}})
    },
    setSmsCode (token: string) {
        return instance.get(`api/user/phone-verify/active`, {headers: {Authorization: `Bearer ${token}`}})
    },
    disableSmsCode (token: string) {
        return instance.get(`api/user/phone-verify/disable`, {headers: {Authorization: `Bearer ${token}`}})
    },
    disable2faCode (token: string) {
        return instance.get(`api/2fa/disable`, {headers: {Authorization: `Bearer ${token}`}})
    },
    getStatement (token: string, accountInfo: any) {
        return instance.post(`api/file/statment/pdf`, accountInfo, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendContactForm (data: IContactData) {
        return instance.post(`api/mailer/sendContactFormData`, data)
    },
    sendNewEmail (token: string, email: { email: string; }) {
        return instance.post(`api/email/create`, email, {headers: {Authorization: `Bearer ${token}`}})
    },
    sendEmailConfirmationCode (emailId: string | undefined, code: string, token: string) {
        return instance.post(`api/email/verify`, {emailId, code}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getNewEmail (token: string) {
        return instance.get(`api/user/email`, {headers: {Authorization: `Bearer ${token}`}})
    },
    getUserEmailsList (token: string) {
        return instance.get(`api/email/list`, {headers: {Authorization: `Bearer ${token}`}})
    },
    deleteUserEmail (emailId: number, token: string) {
        return instance.post(`api/email/delete`, {emailId}, {headers: {Authorization: `Bearer ${token}`}})
    },
    getUserEmailVerifyCode (token: string, emailId: number) {
        return instance.post(`api/email/send/verify/code`, {emailId} , {headers: {Authorization: `Bearer ${token}`}})
    },
    setUserEmailAsMain (token: string, emailId: number) {
        return instance.post(`api/email/set-main`, {emailId} , {headers: {Authorization: `Bearer ${token}`}})
    },
}
