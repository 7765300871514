import {
    ABOUT, AGENT_PROGRAM, CONTACTS, FAQ, FEES,
    FOREIGN_EXCHANGE,
    MONEY_ORDERS,
    MONEY_TRANSFERS,
    PAYMENT_SERVICE_PROVIDER, WHY_US
} from "./RoutesConstants";
import About from "../pages/PublicPages/About/About";
import ForeignExchange from "../pages/PublicPages/Services/ForeignExchange/ForeignExchange";
import MoneyOrders from "../pages/PublicPages/Services/MoneyOrders/MoneyOrders";
import MoneyTransfers from "../pages/PublicPages/Services/MoneyTransfers/MoneyTransfers";
import PaymentServiceProvider from "../pages/PublicPages/Services/PaymentServiceProvider/PaymentServiceProvider";
import WhyUs from "../pages/PublicPages/WhyUs/WhyUs";
import Faq from "../pages/PublicPages/Faq/Faq";
import Contacts from "../pages/PublicPages/Contacts/Contacts";
import Fees from "../pages/PublicPages/Fees/Fees";
import AgentProgram from "../pages/PublicPages/AgentProgram/AgentProgram";

export const publicRoutes = [
    {
        name: 'About Us',
        path: ABOUT,
        element: <About/>
    },
    {
        name: 'Why Us?',
        path: WHY_US,
        element: <WhyUs/>
    },
    {
        name: 'FAQ',
        path: FAQ,
        element: <Faq/>
    },
    {
        name: 'FEES',
        path: FEES,
        element: <Fees/>
    },
    {
        name: 'Contacts',
        path: CONTACTS,
        element: <Contacts/>
    },
    {
        name: 'Agent Program',
        path: AGENT_PROGRAM,
        element: <AgentProgram/>
    },
]

export const ServicesPublicRoutes = [
   /* {
        path: VIRTUAL_CURRENCIES,
        element: <VirtualCurrencies/>
    },*/
    {
        path: FOREIGN_EXCHANGE,
        element: <ForeignExchange/>
    },
    {
        path: MONEY_ORDERS,
        element: <MoneyOrders/>
    },
    {
        path: MONEY_TRANSFERS,
        element: <MoneyTransfers/>
    },
    {
        path: PAYMENT_SERVICE_PROVIDER,
        element: <PaymentServiceProvider/>
    },
]
